import { makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    maxWidth: 856,
    margin: "0 auto",
    backgroundColor: theme.palette.common.white,
    borderRadius: 24,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      borderRadius: 16
    }
  },
  heading: {
    fontSize: 24,
    fontWeight: 600,
    color: "#111827",
    marginBottom: theme.spacing(2),
    textAlign: "left",
    lineHeight: "28px"
  },
  visitSection: {
    backgroundColor: "#F9FAFB",
    padding: theme.spacing(4),
    gap: theme.spacing(5),
    borderRadius: 16,
    border: "1px solid rgba(215, 215, 215, 1)",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      gap: theme.spacing(3)
    }
  },
  visitHeader: {
    fontSize: 16,
    fontWeight: 500,
    color: "#111827",
    marginBottom: theme.spacing(2),
    lineHeight: "28px",
  },
  columnGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gap: theme.spacing(4)
    }
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  columnHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  columnIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  },
  columnTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#6B7280",
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  infoItem: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(2)
  },
  icon: {
    color: "#9CA3AF",
    fontSize: 20
  },
  infoContent: {
    flex: 1
  },
  infoLabel: {
    fontSize: 14,
    color: "#6B7280",
    marginBottom: theme.spacing(0.5)
  },
  infoValue: {
    fontSize: 14,
    color: "#111827",
    lineHeight: "20px",
    fontWeight: 500
  },
  expandButton: {
    marginTop: theme.spacing(1),
    color: "#787878",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent"
    }
  },
  expandButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  expandIcon: {
    transition: "transform 0.3s ease",
    transform: props => props.expanded ? "rotate(180deg)" : "rotate(0deg)"
  },
  ratingSection: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }
  },
  ratingQuestion: {
    fontSize: 16,
    fontWeight: 500,
    color: "#111827",
    marginBottom: theme.spacing(4),
    textAlign: "left",
    alignSelf: "flex-start"
  },
  ratingContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0.5)
    }
  },
  starButton: {
    padding: 0,
    minWidth: "auto",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      "& $starIcon, & $starIconHover, & $starIconSelected": {
        width: 32,
        height: 32
      }
    }
  },
  starIcon: {
    width: 40,
    height: 40,
    display: "block",
    "& path": {
      fill: "#E5E7EB"
    }
  },
  starIconHover: {
    width: 40,
    height: 40,
    display: "none",
    "& path": {
      fill: "#FED94F"
    }
  },
  starIconSelected: {
    width: 40,
    height: 40,
    "& path": {
      fill: "#FED94F"
    }
  },
  ratingLabels: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 384,
    width: "100%",
    margin: "0 auto",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "center",
      gap: theme.spacing(4),
      marginBottom: theme.spacing(4),
      maxWidth: 280,
      width: "auto"
    }
  },
  ratingLabel: {
    fontSize: 14,
    color: "#6B7280",
    [theme.breakpoints.down("sm")]: {
      "&:nth-child(2)": {
        display: "none"
      }
    }
  },
  cancelSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    padding: theme.spacing(1),
    minWidth: "auto",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      "& $cancelIconHover": {
        display: "block"
      },
      "& $cancelIcon": {
        display: "none"
      }
    }
  },
  cancelIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    borderRadius: theme.spacing(1),
    transition: "background-color 0.2s ease",
    "&.selected": {
      "& $cancelIcon": {
        display: "none"
      },
      "& $cancelIconHover": {
        display: "block"
      }
    }
  },
  cancelIcon: {
    width: 40,
    height: 40,
    display: "block"
  },
  cancelIconHover: {
    width: 40,
    height: 40,
    display: "none",
    color: "#FF4842"
  },
  cancelText: {
    fontSize: 14,
    color: "#6B7280",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    textAlign: "center"
  },
  submitButton: {
    width: 384,
    margin: "0 auto",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.25),
    backgroundColor: "#20A869",
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
    textTransform: "none",
    borderRadius: 4,
    gap: theme.spacing(1.25),
    "&:hover": {
      backgroundColor: "#198754"
    },
    "&:disabled": {
      backgroundColor: "#E5E7EB",
      color: "#9CA3AF"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none"
    }
  },
  errorSubmitButton: {
    width: 384,
    margin: "0 auto",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.25),
    backgroundColor: "#B93C3C",
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
    textTransform: "none",
    borderRadius: 4,
    gap: theme.spacing(1.25),
    "&:hover": {
      backgroundColor: "#943030"
    },
    "&:disabled": {
      backgroundColor: "#E5E7EB",
      color: "#9CA3AF"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none"
    }
  },
  footer: {
    fontSize: 10,
    lineHeight: "14px",
    color: "#6B7280",
    textAlign: "center",
    maxWidth: 800,
    margin: "0 auto",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
      lineHeight: "12px",
      marginTop: theme.spacing(3)
    }
  },
  servicesSection: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(2)
  },
  servicesList: {
    margin: 0,
    padding: 0,
    listStyle: "none"
  },
  serviceItem: {
    fontSize: 14,
    color: "#111827",
    lineHeight: "20px",
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
    "&:last-child": {
      marginBottom: 0
    }
  },
  servicesContainer: {
    maxHeight: 100,
    overflow: "hidden",
    position: "relative",
    transition: "max-height 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 80
    }
  },
  servicesContainerExpanded: {
    maxHeight: "none"
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  questionSection: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3)
    }
  },
  questionNumber: {
    fontSize: 16,
    fontWeight: 500,
    color: "#111827",
    marginBottom: theme.spacing(3),
    "& .optional": {
      color: "#6B7280",
      fontWeight: 400,
    }
  },
  ratingGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    maxWidth: 440,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(5, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      gap: theme.spacing(0.5),
      width: "80%",
      margin: "0 auto",
      maxWidth: "none",
      padding: 0
    }
  },
  ratingButton: {
    width: 44,
    height: 44,
    minWidth: 44,
    borderRadius: "50%",
    border: "2px solid #E5E7EB",
    padding: 0,
    color: "#111827",
    fontSize: 14,
    backgroundColor: "transparent",
    transition: "all 0.2s",
    "&:hover": {
      borderColor: "#4B5563",
      backgroundColor: "#F3F4F6"
    },
    "&.selected": {
      backgroundColor: "#4B5563",
      borderColor: "#4B5563",
      color: "#FFFFFF"
    },
    [theme.breakpoints.down("sm")]: {
      width: 48,
      height: 48,
      minWidth: 48,
      fontSize: 16
    }
  },
  noteField: {
    marginBottom: theme.spacing(4),
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#E5E7EB"
      },
      "&:hover fieldset": {
        borderColor: "#9CA3AF"
      }
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      "&::placeholder": {
        color: "#9CA3AF"
      }
    },
    "& .MuiFormHelperText-root": {
      fontSize: 12,
      color: "#9CA3AF",
      marginLeft: "auto",
      marginRight: 0
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3)
    }
  }
}))
