import { makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    maxWidth: 856,
    margin: "0 auto",
    backgroundColor: theme.palette.common.white,
    borderRadius: 24,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(4),
    textAlign: "center"
  },
  icon: {
    width: 72,
    height: 72,
    color: "#9CA3AF",
    marginBottom: theme.spacing(3)
  },
  heading: {
    fontSize: 36,
    fontWeight: 600,
    color: "#111827",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "32px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  message: {
    fontSize: 16,
    color: "#6B7280",
    maxWidth: 500,
    margin: "0 auto",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "24px",
      marginBottom: theme.spacing(5)
    }
  }
}))
