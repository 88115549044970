import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Paper, TextField,Typography } from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"

import calendarIcon from "../../assets/images/survey/calendar.svg"
import institutionIcon from "../../assets/images/survey/institution.svg"
import labIcon from "../../assets/images/survey/lab.svg"
import StarIcon from "../../assets/images/survey/star.svg"
import StarBorderIcon from "../../assets/images/survey/star-border.svg"
import { useStyles } from "./FirstStep.styles"

interface FirstStepProps {
  data: {
    patientName: string
    clinicName: string
    clinicAddress: string
    visitDate: string
    visitTime: string
    services: string[]
  }
  rating: number
  rating10: number
  note: string
  onRatingChange: (rating: number) => void
  onRating10Change: (rating: number) => void
  onNoteChange: (note: string) => void
  onSubmit: () => Promise<void>
  onSubmitSuccess: () => void
}

export const FirstStep: React.FC<FirstStepProps> = ({
  data,
  rating,
  rating10,
  note,
  onRatingChange,
  onRating10Change,
  onNoteChange,
  onSubmit,
  onSubmitSuccess
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const [needsExpansion, setNeedsExpansion] = React.useState(false)
  const servicesRef = React.useRef<HTMLDivElement>(null)
  const classes = useStyles({ expanded })
  const { t } = useTranslation()

  React.useEffect(() => {
    if (servicesRef.current) {
      const { scrollHeight, clientHeight } = servicesRef.current
      setNeedsExpansion(scrollHeight > clientHeight)
    }
  }, [data.services])

  const handleRatingClick = async (newRating: number) => {
    await onRatingChange(newRating)
  }

  const handleRating10Change = (newRating: number) => {
    onRating10Change(newRating)
  }

  const handleSubmit = async () => {
    await onSubmit()
    onSubmitSuccess()
  }

  return (
    <div
      data-testid="first-step-container"
      data-rating={rating}
    >
      <Paper className={classes.mainCard}>
        <Typography className={classes.heading}>
          {t("consultationRating:firstStep:heading", { name: data.patientName })}
        </Typography>

        <Typography className={classes.visitHeader}>
          {t("consultationRating:firstStep:subheading")}
        </Typography>

        <Box className={classes.visitSection}>
          <Box className={classes.columnGrid}>
            <Box className={classes.column}>
              <Box className={classes.columnHeader}>
                <img
                  src={institutionIcon}
                  alt="institution icon"
                  className={classes.columnIcon}
                />
                <Typography className={classes.columnTitle}>
                  {t("consultationRating:firstStep:clinic:title")}
                </Typography>
              </Box>
              <Box className={classes.infoContent}>
                <Typography className={classes.infoValue}>{data.clinicName}</Typography>
                <Typography className={classes.infoValue}>{data.clinicAddress}</Typography>
              </Box>
            </Box>

            <Box className={classes.column}>
              <Box className={classes.columnHeader}>
                <img
                  src={calendarIcon}
                  alt="calendar icon"
                  className={classes.columnIcon}
                />
                <Typography className={classes.columnTitle}>
                  {t("consultationRating:firstStep:date:title")}
                </Typography>
              </Box>
              <Box className={classes.infoContent}>
                <Typography className={classes.infoValue}>{data.visitDate}</Typography>
                <Typography className={classes.infoValue}>Godzina: {data.visitTime}</Typography>
              </Box>
            </Box>

            <Box className={classes.column}>
              <Box className={classes.columnHeader}>
                <img
                  src={labIcon}
                  alt="lab icon"
                  className={classes.columnIcon}
                />
                <Typography className={classes.columnTitle}>
                  {t("consultationRating:firstStep:services:title")}
                </Typography>
              </Box>
              <Box
                ref={servicesRef}
                className={`${classes.servicesContainer} ${expanded ? classes.servicesContainerExpanded : ""}`}
              >
                {data.services.map((service, index) => (
                  <Typography key={index} className={classes.serviceItem}>
                    {service}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          {needsExpansion && (
            <Box className={classes.expandButtonContainer}>
              <Button
                className={classes.expandButton}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? t("consultationRating:firstStep:services:collapse") : t("consultationRating:firstStep:services:expand")}
                <ExpandMore className={classes.expandIcon} />
              </Button>
            </Box>
          )}
        </Box>

        <Box className={classes.ratingSection}>
          <Typography className={classes.ratingQuestion}>
            {t("consultationRating:firstStep:rating:question")}
          </Typography>

          <div className={classes.ratingContainer} data-testid="first-step-rating">
            {[1, 2, 3, 4, 5].map((star) => (
              <Button
                key={star}
                className={classes.starButton}
                data-testid={`rating-star-${star}`}
                onClick={() => handleRatingClick(star)}
              >
                <img
                  src={star <= rating ? StarIcon : StarBorderIcon}
                  alt={`${star} stars`}
                  className={star <= rating ? classes.starIconSelected : classes.starIcon}
                />
              </Button>
            ))}
          </div>

          <Box className={classes.ratingLabels}>
            <Typography className={classes.ratingLabel}>
              {t("consultationRating:firstStep:rating:ratingMin")}
            </Typography>
            <Typography className={`${classes.ratingLabel} ${classes.hideOnMobile}`}>
              {t("consultationRating:firstStep:rating:ratingMiddle")}
            </Typography>
            <Typography className={classes.ratingLabel}>
              {t("consultationRating:firstStep:rating:ratingMax")}
            </Typography>
          </Box>

          {rating > 0 && (
            <>
              <Box className={classes.questionSection}>
                <Typography className={classes.questionNumber}>
                  1. {t("consultationRating:secondStep:question1:text")} <span className="optional">{t("consultationRating:secondStep:question1:optional")}</span>
                </Typography>

                <Box data-testid="second-step-rating" className={classes.ratingGrid}>
                  {[1,2,3,4,5,6,7,8,9,10].map((number) => (
                    <button
                      key={number}
                      className={`${classes.ratingButton} ${rating10 === number ? "selected" : ""}`}
                      onClick={() => handleRating10Change(number)}
                    >
                      {number}
                    </button>
                  ))}
                </Box>
                <Box className={classes.ratingLabels}>
                  <span>{t("consultationRating:secondStep:question1:ratingMin")}</span>
                  <span>{t("consultationRating:secondStep:question1:ratingMax")}</span>
                </Box>
              </Box>

              <Box className={classes.questionSection}>
                <Typography className={classes.questionNumber}>
                  2. {t("consultationRating:secondStep:question2:text")} <span className="optional">{t("consultationRating:secondStep:question2:optional")}</span>
                </Typography>

                <TextField
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder={t("consultationRating:secondStep:question2:placeholder")}
                  value={note}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (newValue.length <= 500) {
                      onNoteChange(newValue)
                    }
                  }}
                  className={classes.noteField}
                  fullWidth
                />
              </Box>

              <Button
                data-testid="submit-survey-button"
                className={classes.submitButton}
                onClick={handleSubmit}
              >
                {t("consultationRating:secondStep:submitButton")}
              </Button>
            </>
          )}

          <Typography className={classes.footer}>
            {t("consultationRating:footer")}
          </Typography>
        </Box>
      </Paper>
    </div>
  )
}
