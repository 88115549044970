import React from "react"
import { useTranslation } from "react-i18next"
import { AppBar, Toolbar, Typography } from "@material-ui/core"
import i18next from "i18next"

import store from "../../store"
import Logotype from "../common/logotype/Logotype"
import { useStyles } from "./RatingTopBar.styles"
import { useTheme } from "@material-ui/core/styles"

interface RatingTopBarProps {
  showLogo?: boolean
}

const RatingTopBar: React.FC<RatingTopBarProps> = ({ showLogo }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const clinicSettings = store.getState().clinic.clinicSettings
  const customLogoType = clinicSettings?.frontendSettings?.images?.logoWatermark

  const loginUrl = `https://${clinicSettings.domain}/${i18next.language}`

  return (
    <AppBar className={`${classes.root} ${showLogo ? "" : ""}`}>
      <Toolbar className={classes.toolbarRoot}>
        <Typography variant="h1">
          {customLogoType ? (
            <img
              className={classes.customLogoType}
              src={customLogoType}
              alt={window.location.origin}
            />
          ) : (
            <Logotype
              title={t("mainPage")}
              logoFill={theme.palette.primary.main}
              textFill={theme.palette.text.primary}
            />
          )}
        </Typography>
        <a
          href={loginUrl}
          className={classes.loginButton}
        >
          {t("login:logIn")}
        </a>
      </Toolbar>
    </AppBar>
  )
}

export default RatingTopBar
