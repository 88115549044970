import {createStyles, makeStyles, Theme} from "@material-ui/core"

export const TOP_BAR_HEIGHT_SPACING = 11
export const TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING = 8

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&&": {
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
        position: "static",
        [theme.breakpoints.up("md")]: {
          backgroundColor: theme.palette.background.appBar,
          marginBottom: theme.spacing(TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING),
          minHeight: theme.spacing(TOP_BAR_HEIGHT_SPACING),
          position: "fixed",
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(1),
          minHeight: 48,
          height: "auto",
          "& $toolbarRoot": {
            minHeight: 48,
            padding: theme.spacing(0.5, 1)
          }
        }
      }
    },
    toolbarRoot: {
      minHeight: theme.spacing(TOP_BAR_HEIGHT_SPACING),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down("sm")]: {
        minHeight: 48,
        padding: theme.spacing(0.5, 1)
      }
    },
    backButton: {
      position: "absolute",
      top: theme.spacing(3),
      left: "0",
    },
    backButtonIcon: {
      fontSize: theme.spacing(3.5),
    },
    customLogoType: {
      maxWidth: theme.spacing(21.5),
      maxHeight: theme.spacing(6),
      margin: theme.spacing(2, 0),
    },
    loginButton: {
      marginLeft: "auto",
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(0.5, 2),
      minWidth: 120,
      textDecoration: "none",
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
      "&:hover": {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)
