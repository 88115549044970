import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Paper, Typography } from "@material-ui/core"

import { telemediUrl } from "../../app.config"
import ThumbUpIcon from "../../assets/images/survey/thumb-up.svg"
import { useStyles } from "./ThankYouStep.styles"

export const ThankYouStep: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper data-testid="thank-you-container" className={classes.mainCard}>
      <img src={ThumbUpIcon} alt={t("consultationRating.common.thumbsUp")} className={classes.icon} />
      <Typography className={classes.heading}>
        {t("consultationRating:thankYouStep:heading")}
      </Typography>
      <Typography className={classes.message}>
        {t("consultationRating:thankYouStep:message")}
      </Typography>
      <Button
        variant="contained"
        className={classes.button}
        href={telemediUrl}
      >
        {t("consultationRating:thankYouStep:backToHomepage")}
      </Button>
    </Paper>
  )
}
