import React from "react"
import { useTranslation } from "react-i18next"
import { Paper, Typography } from "@material-ui/core"

import ThumbDownIcon from "../../assets/images/survey/thumb-down.svg"
import { useStyles } from "./SurveyLocked.styles"

export const SurveyLocked: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper data-testid="survey-locked-container" className={classes.mainCard}>
      <img src={ThumbDownIcon} alt={t("consultationRating.common.thumbsDown")} className={classes.icon} />
      <Typography className={classes.heading}>
        {t("consultationRating:surveyLocked:heading")}
      </Typography>
      <Typography className={classes.message}>
        {t("consultationRating:surveyLocked:message")}
      </Typography>
    </Paper>
  )
}
